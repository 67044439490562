import { useContext, KeyboardEvent, ChangeEvent } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { useLanguage } from '@/hooks/useLanguage';
import { useRouter } from 'next/navigation';
import { TicketsContext } from '@/contexts/TicketsContext';

export const Search = () => {
  const { lang } = useLanguage()
  const router = useRouter()

  const { 
    inputFilter, 
    setInputFilter, 
    fetchTickets
  } = useContext(TicketsContext);

  const onClick = () => {
    fetchTickets()
    router.replace(`/tickets/1`)
  }

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      e.preventDefault();
      onClick()
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputFilter(e.target.value)
  }
  
  return (
    <TextField
      size="small"
      placeholder={lang('search.navbar')}
      className="w-full md:w-auto"
      InputProps={{
        className: 'bg-white dark:bg-slate-800 dark:text-slate-400 text-slate-500 outline-none shadow-none font-light font-main text-xs w-full p-0 m-0 min-w-auto rounded-full',
        endAdornment: (<InputAdornment position="end">
            <Tooltip title={lang('click.or.enter')} placement="top" arrow>
                <span>
                  <Button 
                    onClick={onClick} 
                    className="min-w-auto disabled:cursor-default"
                    disabled={!inputFilter}
                  >
                    <SearchIcon className="text-gray-500 dark:text-slate-200" />
                  </Button>
                </span>
            </Tooltip>
          </InputAdornment>),
        sx: {
          "& fieldset": {
            borderColor: "#F3F4F6 !important",
            borderWidth: "0px"
          },
          "&:hover fieldset": {
            borderColor: "#F3F4F6 !important"
          },
          "&.Mui-focused fieldset": {
            borderColor: "#F3F4F6 !important"
          },
          "& input::placeholder": {
            fontSize: "10px !important",
            color: "rgb(51 65 85)"
          },
          ".dark & input::placeholder": {
            color: "#FFF"
          }
        }
      }}
      variant="outlined"
      onKeyDown={onKeyDown}
      onChange={onChange}
      value={inputFilter}
      aria-label="Search Sessions"
    />
  )
}