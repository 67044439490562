import React, { useContext, MouseEvent, useEffect } from 'react'
import { useRouter } from 'next/navigation'
import useDeviceDetection from '@/hooks/useDeviceDetection'
import { ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material'
import { AppContext } from '@/contexts/AppContext'
import { DevicesEnum } from '@/enum/Devices'

interface SidebarItemProps {
  text: string
  icon: any
  active: boolean
  url?: string | undefined
  notification?: number
  onClick?: () => void
}

export const SidebarItem: React.FC<SidebarItemProps> = ({ text, url, icon, notification, active, onClick = () => {} }) => {
  const { navOpen, setNavOpen, setNavLoading } = useContext(AppContext)
  const device = useDeviceDetection()
  const router = useRouter()

  const handleClick = async (e: MouseEvent<HTMLDivElement>, url?: string) => {
    e.preventDefault()
    e.stopPropagation()

    if(url){
      setNavLoading(true)
      router.push(url)
      if (device !== DevicesEnum.desktop) {
        setNavOpen(false)
      }
    }else{
      onClick()
    }
  }

  useEffect(() => {
    return () => setNavLoading(false)
  }, [])

  return (
    <ListItem disablePadding className="block">
      <ListItemButton
        onClick={(e) => handleClick(e, url)} 
        className={`${navOpen ? 'px-3' : 'px-0' } normal-case relative font-normal text-center min-w-auto py-3.5 hover:bg-tertiary ${active ? 'bg-tertiary dark:bg-slate-900' : 'bg-transparent'}`}
      >
        <Tooltip title={text} placement="top" arrow>
          <ListItemIcon className="text-white flex gap-2 items-center justify-center">
            {icon}
            {navOpen ? <span className="text-xs">{text}</span> : '' }
            {notification ? <span className="flex items-center justify-center absolute right-0 top-0 bg-red-500 text-white dark:bg-slate-900 text-xs font-medium rounded-full w-5 h-5">{notification}</span> : ''}
          </ListItemIcon>
        </Tooltip>
      </ListItemButton>
    </ListItem>
  )
}